import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			selectedImageIndex: undefined
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		//console.log("facility==>" + parseInt(this.$el.dataset.facilityid, 10));
		let el = document.querySelector("[data-facility-id]");
		var facilityId;
		if (el) {
			facilityId = parseInt(el.getAttribute('data-facility-id'), 10);
		} else {
			console.log('element is not found..................');
		}
		//console.log('facilityId::' + facilityId);

		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var unitgroupid;
		if (facilityId == 196280) { //Goldbachmühle
			unitgroupid = 48078;
		} else if (facilityId == 208471) { //Strandschlösschen Haus II
			unitgroupid = 55964;
		} else if (facilityId == 196507) { //Cubanzestraße 
			unitgroupid = 48297;
		} else if (facilityId == 196251) { //Cubanzestraße 
			unitgroupid = 48047;
		} else if (facilityId == 196288) { //LakeSpace 
			unitgroupid = 48085;
		} else if (facilityId == 196289) { // Landhaus zum See
			unitgroupid = 48086;
		} else if (facilityId == 196287) { //Parkvilla Schachen 7 
			unitgroupid = 48084;
		} else if (facilityId == 196264) { //Patrizierhaus Münsterstraße
			unitgroupid = 48058;
		} else if (facilityId == 196265) { //Residenz am See 
			unitgroupid = 48059;
		} else if (facilityId == 196284) { //Residenz am See 
			unitgroupid = 48083;
		} else if (facilityId == 196266) { //Residenz am See 
			unitgroupid = 48060;
		} else if (facilityId == 196237) { //Residenz am See 
			unitgroupid = 48030;
		} else if (facilityId == 196245) { //Residenz am See 
			unitgroupid = 48039;
		} else if (facilityId == 196254) { //Residenz am See 
			unitgroupid = 48051;
		} else if (facilityId == 196241) { //Residenz am See 
			unitgroupid = 48034;
		} else if (facilityId == 196352) { //Residenz am See 
			unitgroupid = 48121;
		} else if (facilityId == 196504) { //Residenz am See 
			unitgroupid = 48292;
		} else if (facilityId == 196505) { //Residenz am See 
			unitgroupid = 48293;
		} else if (facilityId == 196506) { //Residenz am See 
			unitgroupid = 48295;
		} else if (facilityId == 196508) { //Residenz am See 
			unitgroupid = 48299;
		} else if (facilityId == 196509) { //Residenz am See 
			unitgroupid = 48300;
		} else if (facilityId == 196510) { //Residenz am See 
			unitgroupid = 48301;
		} else if (facilityId == 196511) { //Residenz am See 
			unitgroupid = 48303;
		} else if (facilityId == 196512) { //Residenz am See 
			unitgroupid = 48305;
		} else if (facilityId == 196513) { //Residenz am See 
			unitgroupid = 48306;
		} else if (facilityId == 196262) { //Residenz am See 
			unitgroupid = 48056;
		} else if (facilityId == 196514) { //Residenz am See 
			unitgroupid = 48307;
		} else if (facilityId == 196515) { //Residenz am See 
			unitgroupid = 48309;
		} else if (facilityId == 196517) { //Residenz am See 
			unitgroupid = 48311;
		} else if (facilityId == 196518) { //Residenz am See 
			unitgroupid = 48312;
		} else if (facilityId == 196519) { //Residenz am See 
			unitgroupid = 48314;
		} else if (facilityId == 196520) { //Residenz am See 
			unitgroupid = 48320;
		} else if (facilityId == 196521) { //Residenz am See 
			unitgroupid = 48323;
		} else if (facilityId == 196523) { //Residenz am See 
			unitgroupid = 48324;
		} else if (facilityId == 196524) { //Residenz am See 
			unitgroupid = 48326;
		} else if (facilityId == 196525) { //Residenz am See 
			unitgroupid = 48327;
		} else if (facilityId == 196526) { //Residenz am See 
			unitgroupid = 48328;
		} else if (facilityId == 196527) { //Residenz am See 
			unitgroupid = 48329;
		} else if (facilityId == 196530) { //Residenz am See 
			unitgroupid = 48330;
		} else if (facilityId == 196531) { //Residenz am See 
			unitgroupid = 48331;
		} else if (facilityId == 196532) { //Residenz am See 
			unitgroupid = 48332;
		} else if (facilityId == 196258) { //Residenz am See 
			unitgroupid = 48054;
		} else if (facilityId == 196268) { //Residenz am See 
			unitgroupid = 48062;
		} else if (facilityId == 196277) { //Residenz am See 
			unitgroupid = 48074;
		} else if (facilityId == 196250) { //Residenz am See 
			unitgroupid = 48046;
		} else if (facilityId == 196274) { //Residenz am See 
			unitgroupid = 48068;
		} else if (facilityId == 196279) { //Residenz am See 
			unitgroupid = 48076;
		} else if (facilityId == 196283) { //Residenz am See 
			unitgroupid = 48082;
		} else if (facilityId == 196261) { //Residenz am See 
			unitgroupid = 48055;
		} else if (facilityId == 196350) { //Residenz am See 
			unitgroupid = 48119;
		} else if (facilityId == 196248) { //Residenz am See 
			unitgroupid = 48043;
		} else if (facilityId == 196247) { //Residenz am See 
			unitgroupid = 48041;
		} else if (facilityId == 196244) { //Residenz am See 
			unitgroupid = 48038;
		} else if (facilityId == 196249) { //Residenz am See 
			unitgroupid = 48045;
		} else if (facilityId == 196275) { //Residenz am See 
			unitgroupid = 48069;
		} else if (facilityId == 196242) { //Residenz am See 
			unitgroupid = 48035;
		} else if (facilityId == 196255) { //Residenz am See 
			unitgroupid = 48052;
		} else if (facilityId == 196295) { //Residenz am See 
			unitgroupid = 48091;
		} else if (facilityId == 196290) { //Residenz am See 
			unitgroupid = 48087;
		} else if (facilityId == 196351) { //Residenz am See 
			unitgroupid = 48120;
		} else if (facilityId == 196246) { //Residenz am See 
			unitgroupid = 48040;
		} else if (facilityId == 196294) { //Residenz am See 
			unitgroupid = 48090;
		} else if (facilityId == 196281) { //Residenz am See 
			unitgroupid = 48080;
		} else if (facilityId == 196273) { //Residenz am See 
			unitgroupid = 48067;
		} else if (facilityId == 196278) { //Residenz am See 
			unitgroupid = 48075;
		} else if (facilityId == 196276) { //Residenz am See 
			unitgroupid = 48070;
		} else if (facilityId == 196269) { //Residenz am See 
			unitgroupid = 48063;
		} else if (facilityId == 196243) { //Residenz am See 
			unitgroupid = 48036;
		} else if (facilityId == 196270) { //Residenz am See 
			unitgroupid = 48064;
		} else if (facilityId == 196282) { //Residenz am See 
			unitgroupid = 48081;
		} else if (facilityId == 196292) { //Residenz am See 
			unitgroupid = 48088;
		} else if (facilityId == 196293) { //Residenz am See 
			unitgroupid = 48089;
		} else if (facilityId == 196252) { //Residenz am See 
			unitgroupid = 48048;
		} else if (facilityId == 196240) { //Residenz am See 
			unitgroupid = 48033;
		} else if (facilityId == 196271) { //Residenz am See 
			unitgroupid = 48065;
		} else if (facilityId == 196272) { //Residenz am See 
			unitgroupid = 48066;
		} else if (facilityId == 196263) { //Residenz am See 
			unitgroupid = 48057;
		} else if (facilityId == 196257) { //Residenz am See 
			unitgroupid = 48053;
		} else if (facilityId == 201245) { //Residenz am See 
			unitgroupid = 52060;
		} else if (facilityId == 202112) { //Residenz am See 
			unitgroupid = 52386;
		} else if (facilityId == 203101) { //Residenz am See 
			unitgroupid = 53108;
		}


		console.log("unitgroupid==>" + unitgroupid);

		var req = {
			fields: this.resultFields,
			filter: {
				//unitgroupid: 25551
				//'region.id': 8155
				unitgroupid: unitgroupid
			},
			sorting: 'random',
			//pricelist: true,
			max: 100
		};

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;
			});

	},

	created() {
		window.addEventListener("resize", this.myEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.myEventHandler);
	},

	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
		$(".owl-carousel.region").owlCarousel({
			loop: false,
			rewind: true,
			margin: 30,
			smartSpeed: 1000,
			autoplay: false,
			nav: true,
			responsive: {
				0: {
					items: 1
				},
				768: {
					items: 2
				},
				996: {
					items: 3

				}
			}
		});
	},
	methods: {
		getUnitSearchTarget: function (id) {
			return '_self';
			/*	if (window.screen.width < 768) {
					//probably phone?
					return '_self';
				} else {
					return 'unit_' + id;
				} */
		},
		getUnitgroupId: function (id) {
			return 44849;
		},
		getfacilityId: function (id) {
			return parseInt(this.$el.dataset.facilityid, 10);
		},

		myEventHandler(e) {
			//	console.log(window.innerWidth);
		}
	}

};